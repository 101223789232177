<template>
  <div class="page-opener mb-10" :class="[
      $props.data.Image.data || $props.data.icon ? 'has-image' : ''
  ]">
    <div class="opener-image flex justify-center items-center">
      <img v-if="$props.data.Image.data" class="h-full w-auto" :src="imageUrl" :alt="$props.data.Image.data.attributes.alternativeText" :title="$props.data.Image.data.attributes.caption" />
      <Icons v-if="$props.data.icon" :type="$props.data.icon" classes="text-icon-xl text-green" />
    </div>
    <Headline v-if="$props.data.Headline" classes="text-center h1" :type="headlineType" :text="$props.data.Headline"/>
    <div class="opener-text text body1 text-center" v-if="$props.data.Text" v-html="$props.data.Text"></div>
  </div>
</template>

<script>
import {defineAsyncComponent} from "vue";
import ApiService from "@/services/apiService";

const Headline = defineAsyncComponent(() => import('@/components/Headline'));
const Icons = defineAsyncComponent(() => import('@/components/Icons'));
export default {
  name: "PageOpener",
  props: ["data"],
  data() {
    return {
      headlineType: 'h1',
      imageUrl: ''
    }
  },
  components: {
    Headline,
    Icons
  },
  created() {
    if(this.$props.data.Image.data) {
      this.imageUrl = ApiService.getBaseUrl() + this.$props.data.Image.data.attributes.url;
    }
  }
}
</script>

<style scoped lang="scss">
  .page-opener {
    &.has-image {
      position: relative;
      top: -6.5rem;
      margin-top: 5.5rem;
      margin-bottom: -4rem;

      .opener-image {
        margin-bottom: 1.25rem;
        height: 85px;
      }
    }
  }
</style>